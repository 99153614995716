import * as React from 'react';

type Props = {
  section?: string;
  className?: string;
};

const ColourBar: React.SFC<Props> = ({ section, className }) => {
  let believeFlex = '';
  let becomeFlex = '';
  let belongFlex = '';

  if (section) {
    if (section === 'believe') {
      believeFlex = '-section';
    }
    if (section === 'become') {
      becomeFlex = '-section';
    }
    if (section === 'belong') {
      belongFlex = '-section';
    }
  }

  return (
    <div className={`flex${className ? ` ${className}` : ''}`}>
      <div className={`flex-grow${believeFlex} min-h-3 bg-believe`} />
      <div className={`flex-grow${becomeFlex} min-h-3 bg-become`} />
      <div className={`flex-grow${belongFlex} min-h-3 bg-belong`} />
    </div>
  );
};

export default ColourBar;
