/* eslint-disable import/no-duplicates */
import * as React from 'react';
import { Link } from 'gatsby';
import ColourBar from '../colour-bar/colour-bar';

import podBelieve from '../../../images/pod-believe.jpg';
import podBecome from '../../../images/pod-become.jpg';
import podBelong from '../../../images/pod-belong.jpg';

import podBelieveActive from '../../../images/pod-believe-active.jpg';
import podBecomeActive from '../../../images/pod-become-active.jpg';
import podBelongActive from '../../../images/pod-belong-active.jpg';

import podBelieveInactive from '../../../images/pod-believe-inactive.jpg';
import podBecomeInactive from '../../../images/pod-become-inactive.jpg';
import podBelongInactive from '../../../images/pod-belong-inactive.jpg';

const pods = {
  believe: {
    default: podBelieve,
    active: podBelieveActive,
    inactive: podBelieveInactive,
  },
  become: {
    default: podBecome,
    active: podBecomeActive,
    inactive: podBecomeInactive,
  },
  belong: {
    default: podBelong,
    active: podBelongActive,
    inactive: podBelongInactive,
  },
};

type Props = {
  section?: string;
  className?: string;
  otherSections?: boolean;
  hideBottomColourBar?: boolean;
};

const PodBar: React.SFC<Props> = ({
  section,
  className,
  otherSections = false,
  hideBottomColourBar = false,
}) => {
  const getPod = function getPod(selected, force = false) {
    if (!section || force) {
      return pods[selected].default;
    }
    return pods[selected][`${selected !== section ? 'in' : ''}active`];
  };
  const getClass = function getClass(selected) {
    if (
      !section ||
      (!otherSections && section === selected) ||
      (otherSections && section !== selected)
    ) {
      return ' block';
    }
    return ' hidden sm:block';
  };
  // purgecss: flex-grow-section
  return (
    <section className={`bg-white${className ? ` ${className}` : ''}`}>
      <ColourBar section={section} />
      <div className="sm:flex">
        <Link
          className={`sm:flex-grow${
            section === 'believe' ? '-section' : ''
          }${getClass('believe')}`}
          to="/believe"
        >
          <picture>
            <source
              srcSet={getPod('believe', true)}
              media="(max-width: 640px)"
            />
            <img src={getPod('believe')} alt="Believe - Making sense of God" />
          </picture>{' '}
        </Link>
        <Link
          className={`sm:flex-grow${
            section === 'become' ? '-section' : ''
          }${getClass('become')}`}
          to="/become"
        >
          <picture>
            <source
              srcSet={getPod('become', true)}
              media="(max-width: 640px)"
            />
            <img src={getPod('become')} alt="Become - Finding hope in God" />
          </picture>
        </Link>
        <Link
          className={`sm:flex-grow${
            section === 'belong' ? '-section' : ''
          }${getClass('belong')}`}
          to="/belong"
        >
          <picture>
            <source
              srcSet={getPod('belong', true)}
              media="(max-width: 640px)"
            />
            <img src={getPod('belong')} alt="Belong - Building life with God" />
          </picture>
        </Link>
      </div>
      {!hideBottomColourBar && <ColourBar section={section} />}
    </section>
  );
};

export default PodBar;
