import * as React from 'react';

type Props = {
  className?: string;
};

const EmailSignupForm: React.SFC<Props> = ({ className }) => (
  <form
    action="https://believebecomebelong.us20.list-manage.com/subscribe/post?u=ea2faf1d2f6784ede437d02d1&amp;id=1193627197"
    method="post"
    className={`validate w-full max-w-sm m-auto${
      className ? ` ${className}` : ''
    }`}
    noValidate
  >
    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
      <input
        type="text"
        name="b_ea2faf1d2f6784ede437d02d1_1193627197"
        tabIndex={-1}
        defaultValue=""
      />
    </div>
    <div className="flex items-center p-2">
      <input
        className="appearance-none bg-white border border-belong rounded w-full text-neutral-darker mr-3 py-1 px-2 leading-tight focus:outline-none z-10"
        type="email"
        placeholder="Enter email for updates"
        aria-label="Email"
        name="EMAIL"
        required
      />
      <input
        type="hidden"
        name="SOURCE"
        defaultValue="believebecomebelong.uk"
      />
      <button
        className="flex-shrink-0 bg-belong hover:bg-belong text-sm text-white py-1 px-2 rounded z-10"
        name="subscribe"
        type="submit"
      >
        Subscribe
      </button>
    </div>
  </form>
);

export default EmailSignupForm;
