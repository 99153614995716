import React, { useState } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import ColourBar from '../colour-bar/colour-bar';
import PodBar from '../pod-bar/pod-bar';
import logo from '../../../images/logo-main.svg';

type Props = {
  siteTitle: string;
  showImage: boolean;
  section: string;
};

const Header: React.SFC<Props> = ({ siteTitle, showImage, section }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          sanityMenu(type: { eq: "Main Menu" }) {
            links {
              label
              destination {
                slug {
                  current
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <ColourBar section={section} />
          <nav className="flex items-center justify-between flex-wrap bg-white p-3 m-3 rounded-lg opacity-95">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
              <Link to="/">
                <img className="h-12 mr-2" src={logo} alt={siteTitle} />
                <h1 className="hidden">{siteTitle}</h1>
              </Link>
            </div>
            <div className="block lg:hidden">
              <button
                type="button"
                className="flex items-center px-3 py-2 border rounded text-belong border-belong"
                onClick={() => setMenuVisible(!menuVisible)}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="lg:flex-grow" />
              <div
                className={`text-sm uppercase ${
                  menuVisible ? '' : 'hidden'
                } lg:block`}
              >
                {(data.sanityMenu.links || []).map(link => (
                  <Link
                    to={`/${link.destination.slug.current}`}
                    className={`block mt-4 lg:inline-block lg:mt-0 text-${
                      ['believe', 'become', 'belong'].indexOf(
                        link.destination.slug.current
                      ) >= 0
                        ? link.destination.slug.current
                        : 'neutral'
                    } font-semibold hover:text-neutral-darker mr-6`}
                    key={link.label}
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
          {showImage || (section && <PodBar section={section} />) || (
            <ColourBar section={section} />
          )}
        </>
      )}
    />
  );
};

export default Header;
