import * as React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../header/header';
import Footer from '../footer/footer';

import 'typeface-titillium-web';
import '../global.css';
import ogImage from '../../../images/bbb-ogi.jpg';

type Props = {
  children: React.ReactNode;
  showImage?: boolean;
  section?: string;
};

const Layout = ({ children, showImage = false, section }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            {
              property: 'og:title',
              content: data.site.siteMetadata.title,
            },
            {
              property: 'og:image',
              content: ogImage,
            },
          ]}
        >
          <html lang="en" />
          <body className={`bg-${section || 'belong'}-light`} />
        </Helmet>
        <div
          className={`bg-white flex flex-col h-full max-w-full mx-auto font-sans${
            showImage ? ' img' : ' img'
          }`}
        >
          <Header
            siteTitle={data.site.siteMetadata.title}
            showImage={showImage}
            section={section}
          />
          <div className={`${showImage ? '' : 'bg-white '}flex-grow`}>
            {children}
          </div>
          <Footer section={section} />
        </div>
      </>
    )}
  />
);

export default Layout;
