import * as React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import PodBar from '../pod-bar/pod-bar';
import ColourBar from '../colour-bar/colour-bar';
import EmailSignupForm from '../email-signup-form/email-signup-form';

import logo from '../../../images/whitestone-nuneaton-christadelphians.svg';
import iconFacebook from '../../../images/icon-facebook.svg';
import iconTwitter from '../../../images/icon-twitter.svg';
import iconInstagram from '../../../images/icon-instagram.svg';

type Props = {
  section?: string;
};

const Footer: React.SFC<Props> = ({ section }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        sanityMenu(type: { eq: "Footer" }) {
          links {
            label
            destination {
              slug {
                current
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {section && (
          <PodBar section={section} className="sm:hidden" otherSections />
        )}
        <ColourBar
          section={section}
          className={`${section ? 'hidden sm:flex' : ''}`}
        />
        <footer className="bg-white text-sm py-3">
          <EmailSignupForm className="lg:-mb-10" />
          <div className="flex flex-row items-center mx-3">
            <div className="mr-3">
              <a href="https://facebook.com/bible4nuneaton/">
                <img
                  src={iconFacebook}
                  alt="Find us on Facebook @Bible4Nuneaton"
                />
              </a>
            </div>
            <div className="mr-3">
              <a href="https://twitter.com/bible4nuneaton/">
                <img
                  src={iconTwitter}
                  alt="Find us on Twitter @Bible4Nuneaton"
                />
              </a>
            </div>
            <div className="mr-3">
              <a href="https://instagram.com/bible4nuneaton/">
                <img
                  src={iconInstagram}
                  alt="Find us on Instagram @Bible4Nuneaton"
                />
              </a>
            </div>
            <div className="flex-grow">
              {(data.sanityMenu.links || []).map(link => (
                <Link
                  to={`/${link.destination.slug.current}`}
                  className="block lg:inline-block lg:mt-0 text-center text-neutral hover:text-neutral-darker uppercase font-semibold mr-6"
                  key={link.label}
                >
                  {link.label}
                </Link>
              ))}
            </div>
            <a href="https://www.christadelphians.uk" className="inline-block">
              <img src={logo} alt="Whitestone & Nuneaton Christadelphians" />
            </a>
          </div>
        </footer>
        <ColourBar section={section} />
      </>
    )}
  />
);

export default Footer;
