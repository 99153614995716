import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  title: string;
  formId: string;
};

const AirtableForm: React.SFC<Props> = ({ title, formId }) => (
  <>
    <Helmet>
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
    </Helmet>
    <iframe
      title={title}
      className="airtable-embed airtable-dynamic-height"
      src={`https://airtable.com/embed/${formId}`}
      frameBorder="0"
      width="100%"
      height="934.3333"
    />
  </>
);

export default AirtableForm;
